import React from "react"
import {
  Transition as ReactTransition,
  TransitionGroup,
} from "react-transition-group"

const timeout = 200
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
}

const Transition = ({ children, location }) => {
  return (
    <div style={{ minHeight: "100%" }}>
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {(status) => (
            <div
              style={{
                ...getTransitionStyles[status],
                minHeight: "100%",
              }}
            >
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    </div>
  )
}

export default Transition
